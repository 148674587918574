import { IUserState } from './state';
import { i18n } from '@/store/effects';
import { AsyncAction, Action, json, Context } from '@/store';
import { AccountType } from '@/constants/user';
import {
  IUserResponse,
  IValidateUsernamePayload,
  IValidateUsernameResponse,
} from '@/models/types';
import { User } from 'firebase/auth';
import { ApplicationError } from '@/util/errors';
import { logger } from '@services/logger';

export const clear: Action = ({ state }) => {
  Object.assign(state.user, {
    watchlist: [],
    isSyncingUser: false,
    isLoadingUser: false,
    isUpdatingUser: false,
    id: null,
    name: null,
    email: null,
    phone: null,
    username: null,
    account_type: AccountType.basic,
    notification_id: null,
    profile_picture: null,
    created_at: null,
    error: null,
  });
};

export const loadUser: AsyncAction<void, IUserResponse> = async ({
  state,
  effects,
  actions,
}) => {
  const isWebView = state.auth.isWebView;
  if (isWebView) {
    state.user.hasLoadedBackend = true;
    return;
  }
  state.user.isLoadingUser = true;
  try {
    const token = await actions.auth.getAuthToken('application-read-token');
    const firebaseUser = await effects.auth.getFirebaseUser();
    const user = await effects.user.getUserInfo(token, firebaseUser.uid);
    const tags = await effects.user.getUserTags(token);
    const stringTags = tags.tags.map((tag) => tag.name);
    if (user) {
      Object.assign(state.user, user);
      state.user.id = state.user.user_id; // to not break the old code, we assign the user_id to the id
      state.user.hasLoadedBackend = true;
      actions.feature.setUserId(user.id);
      actions.feature.setUserTags(stringTags);
      user.email && actions.feature.setEmail(user.email);
      effects.analytics.identify(user.id, {
        name: user.name,
        email: user.email,
      });
      await effects.feature.start();
    }
    return user;
  } catch (err) {
    if (
      err?.originalError?.response?.status === 404 ||
      err?.originalError?.response?.status === 400
    ) {
      state.accountAccess.isNewUser = true;
    }
  } finally {
    state.user.isLoadingUser = false;
  }
};

export const validateUsername: AsyncAction<
  string,
  IValidateUsernameResponse
> = async ({ actions, state, effects }, username: string) => {
  const firebaseToken = state.authentication.authTokens['firebase-token'].value;
  const token = await actions.auth.getAuthToken('application-write-token');
  const isValid = await effects.user.validateUsername(token, {
    username,
    credentials: { firebase_token: firebaseToken },
  });
  state.user.is_available = isValid.is_available;
  return isValid;
};

export const syncUserInfo: AsyncAction = async ({
  state,
  effects,
  actions,
}: Context) => {
  state.user.isSyncingUser = true;
  const [user, firebaseUser] = await Promise.all([
    actions.user.loadUser(),
    effects.auth.getFirebaseUser(),
  ]);

  if (!user) return;

  const {
    shouldUpdateUser,
    userPayload,
    shouldUpdateFirebase,
    firebasePayload,
  } = getUpdatePayload(user, firebaseUser);

  await Promise.all([
    shouldUpdateUser && actions.user.updateInfo(userPayload),
    shouldUpdateFirebase && effects.auth.updateFirebaseUser(firebasePayload),
  ]);
};

function getUpdatePayload(user: IUserResponse, firebaseUser: User) {
  const userPayload = {};
  const firebasePayload = {};

  if (user.email !== firebaseUser.email) {
    if (!user.email && firebaseUser.email) {
      userPayload['email'] = firebaseUser.email;
    }
    if (!firebaseUser.email && user.email) {
      firebasePayload['email'] = user.email;
    }
  }

  return {
    shouldUpdateUser: Object.keys(userPayload).length > 0,
    shouldUpdateFirebase: Object.keys(firebasePayload).length > 0,
    userPayload,
    firebasePayload,
  };
}

// adds a stock to user's watchlist
export const watchStock: AsyncAction<string> = async (
  { state, actions },
  stock,
) => {
  const watchlist = [...state.user.watchlist, stock];
  await actions.user.updateInfo({ watchlist });
};

// removes a stock from user's watchlist
export const unwatchStock: AsyncAction<string> = async (
  { state, actions },
  stock,
) => {
  const watchlist = state.user.watchlist.filter(
    (watchedStock) => watchedStock !== stock,
  );
  await actions.user.updateInfo({ watchlist });
};

// removes a stock from user's watchlist
export const registerNotificationToken: AsyncAction<string> = async (
  { actions },
  notification_id,
) => {
  await actions.user.updateInfo({ notification_id });
};

// post user info
export const postInfo: AsyncAction<Partial<IUserState>> = async (
  { state, effects, actions },
  newInfo,
) => {
  const oldUserState = tempSaveOldState(state.user, newInfo);
  state.user.isUpdatingUser = true;
  Object.assign(state.user, newInfo);
  try {
    const token = await actions.auth.getAuthToken('application-write-token');
    await effects.user.postUserInfo(token, newInfo);
  } catch (error) {
    state.user.isUpdatingUser = false;
    effects.notice.showErrorMessage({
      title: 'Something went wrong',
      description: error?.originalError?.response?.data?.detail?.msg,
    });
    Object.assign(state.user, oldUserState);

    throw new ApplicationError(error, 'errors.user.updateInfo');
  } finally {
    state.user.isUpdatingUser = false;
  }
};

// updates user info (name, email) in user-service
export const updateInfo: AsyncAction<Partial<IUserState>> = async (
  { state, effects, actions },
  newInfo,
) => {
  const oldUserState = tempSaveOldState(state.user, newInfo);
  state.user.isUpdatingUser = true;
  Object.assign(state.user, newInfo);
  try {
    const token = await actions.auth.getAuthToken('application-write-token');
    await effects.user.patchUserInfo(token, newInfo);
  } catch (error) {
    Object.assign(state.user, oldUserState);
    throw new ApplicationError(error, 'errors.user.updateInfo');
  } finally {
    state.user.isUpdatingUser = false;
  }
};

// updates user info (username) in auth-service
export const updateUsername: AsyncAction<string> = async (
  { effects, state },
  username,
) => {
  await effects.user.updateUsername({
    token: state.authentication.authTokens.APP_TOKEN.value,
    firebaseToken: state.authentication.authTokens['firebase-token'].value,
    username,
  });
};

export const getPhoneNumber: AsyncAction<any, string> = async ({
  state,
  effects,
}) => {
  try {
    if (state.user.isUpdatingUser) {
      return null;
    }
    if (state.user.phone_number) {
      return state.user.phone_number;
    } else {
      state.user.isUpdatingUser = true;
      const firebaseToken = await effects.auth.getFirebaseToken();
      const { phone_number } = await effects.user.getUserInfoV2({
        firebaseToken,
      });
      state.user.phone_number = phone_number;
      return phone_number;
    }
  } catch (error) {
    logger.error('failed to get user phone number', error);
    return null;
  } finally {
    state.user.isUpdatingUser = false;
  }
};

function tempSaveOldState(
  state: IUserState,
  change: Partial<IUserState>,
): Partial<IUserState> {
  const oldState: Partial<IUserState> = {};
  Object.keys(change).forEach((key) => {
    oldState[key] = json(state[key]);
  });
  return oldState;
}
